import { InterfaceOrientation } from "./interfaceOrientation";
import { MturkGroupId } from "./panels/mturk";
import { AreMediaGroupId } from "./projects/AREM-AreMedia";
import { AustCollGroupId } from "./projects/AUST-AustralianFB";
import { C7AU0122CollTVGroupId } from "./projects/C7AU-Channel7";
import { C9AU1021CollMobileGroupId, C9AU1021CollTVGroupId } from "./projects/C9AU-Channel9";
import { FINE1121TVGroupId } from "./projects/FINE-1121-Finecast";
import { ODEU0422CollGroupId } from "./projects/ODEU-0422";
import { OmdGladeGroupId } from "./projects/OMDG-OmdGlade";
import { REDC0122CollGroupId } from "./projects/REDC-0122";
import { TWIT1221CollGroupId } from "./projects/TWIT-1221";
import { YAHO0422CollGroupId } from "./projects/YAHO-0422";
import { SNAP0522CollGroupId } from "./projects/SNAP-0522";
import { FXTL0722CollGroupId } from "./projects/FXTL-0722-Foxtel";
import { ADCO0622CollGroupId } from "./projects/ADCO-0622";
import { CRAA0822CollGroupId } from "./projects/CRAA-0822";
import { VIAB0822CollGroupId } from "./projects/VIAB-0822";
import { ACAY0822CollGroupId } from "./projects/ACAY-0822";
import { OMNZ1022CollGroupId } from "./projects/OMNZ-1022";
import { OMME1022CollGroupId } from "./projects/OMME-1022";
import { NEAU1022CollGroupId } from "./projects/NEAU-1022";
import { TEAD1222CollGroupId } from "./projects/TEAD-1222";
import { TWIC1222CollGroupId } from "./projects/TWIC-1222";
import { TWIH0123CollGroupId } from "./projects/TWIH-0123";
import { SPOT0123CollGroupId } from "./projects/SPOT-0123";
import { PEPS0523CollGroupId } from "./projects/PEPS-0523";
import { GYGG0623CollGroupId } from "./projects/GYGG-0623";
import { SPIN0823CollGroupId } from "./projects/SPIN-0823";
import { SNAR0823CollGroupId } from "./projects/SNAR-0823";
import { PINT1123CollGroupId } from "./projects/PINT-1123";
import { SKYN0823CollGroupId } from "./projects/SKYN-0823";
import { RCTV0523CollGroupId } from "./projects/RCTV-0523";
import { YAHD0823GroupId } from "./projects/YAHD-0823";
import { GYGG0124CollGroupId } from "./projects/GYGG-0124";
import { SPAU0324CollGroupId } from "./projects/SPAU-0324";
import { ALLW0424CollGroupId } from "./projects/ALLW-0424";
import { SNEM0524CollGroupId } from "./projects/SNEM-0524";
import { GCPL0624CollGroupId } from "./projects/GCPL-0624";
import { GCPL1024CollGroupId } from "./projects/GCPL-1024";
import { GYGG1024CollGroupId } from "./projects/GYGG-1024";
import { OMAU1124CollGroupId } from "./projects/OMAU-1124";


export type DeviceLock = "mobile" | "tablet" | "desktop"

export type RespondentFlags = {
    isApple?: boolean
    isMturk?: boolean
    deviceLock?: DeviceLock
    minimumIOSVersion?: string
    shouldPresentUploadsVC?: boolean
    showDebugVC?: boolean
    requireMinimumBattery?: number
    finishMessageOverride?: string
    preventTaskProgress?: boolean
    disableCollectionEvents?: boolean
    collectionEventApiUrlOverride?: string
    minimumAndroidMobileAppVersion?: string
    minimumAndroidTVAppVersion?: string
    isRecordingAudio?: boolean
    noPermissionsRequired?: boolean
};

interface ITaskMeta {
    /** User friendly title I.E. "Survey", "Youtube", "Calibration" ect */
    title: string
    /** A short 1-2 sentence description of the task */
    description: string
    /** Unique identifier for this task */
    taskID: string
    /** Should this task be displayed on a TV? */
    tv?: {}
}

type TaskType =
    | "gazeCalibration"
    | "gazeCalibrationDesktop"
    | "lumenGazeCalibration"
    | "completionCode"
    | "bvodInApp"
    | "linearTv"
    | "webPlatform"
    | "externalSurvey"
    | "externalLink"
    | "webRequest"
    | "uploads"
    | "snapAR"

export type WebPlatform =
    | 'Youtube'
    | 'Facebook'
    | 'Instagram'
    | 'Twitter'
    | 'Homes To Love'
    | 'Marie Claire'
    | 'Now To Love'
    | 'TikTok'
    | 'Yahoo'
    | 'Snapchat'
    | 'AdColony'
    | 'Stuff'
    | 'NZ Herald'
    | 'OneRoof'
    | 'General'
    | 'Twitch'
    | 'Spotify'
    | 'Pinterest'
    | 'SnapAR'

export type WebRequestMethod =
    | 'HEAD'
    | 'GET'
    | 'POST'
    | 'PATCH'
    | 'PUT'
    | 'DELETE'

export type RespondentCompletionCodeTaskConfig = {
    completionCode: string
}

export type RespondentBvodInAppTaskConfig = {
    channelsJsonUrl: string
}

export type RespondentGazeCalibrationTaskConfig = {
    orientations: ReadonlyArray<InterfaceOrientation>
    showInstructions?: boolean
    pointsPerOrientation: number
    maxPointsOverride?: number
    rotationRange?: number
    maxRotationAngle?: number
    minFramesPerRecording?: number
    staticPointsPerBlock?: number
    movingPointsPerBlock?: number
    presentEachPointWithRotations?: number[]
    preventTaskProgress?: boolean
}

export type RespondentGazeCalibrationDesktopTaskConfig = {
    // Nothing for now. Perhaps in the future.
}

/**
 * 'Lumen' video style gaze calibration task.
 */
export type LumenGazeCalibrationTaskConfig = {
    orientations: ReadonlyArray<InterfaceOrientation>
}

export type RespondentWebPlatformTaskConfig = {
    webPlatformID: string,
    webPlatformConfig: string
}

export type RespondentExternalSurveyTaskConfig = {
    surveyUrl: string,
    extraMetaFields: ReadonlyArray<string>
}

export type RespondentExternalLinkConfig = {
    url: string
}

export type RespondentWebRequestConfig = {
    webRequestConfigUrl: string,
    params: Record<string, string>
}

export type RespondentLinearTvConfig = {
    viewingLengthOptions?: ReadonlyArray<number>
}

/**
 * strings to display in upload screen
 */
export type RespondentUploadsTaskConfig = {
    title?: string,
    subtitle?: string
}

export type RespondentSnapARTaskConfig = {
    lensGroupID: string,
    sessionLength: number
}

type RespondentTaskNoConfig = Record<string, never>

type TaskConfig =
    | RespondentGazeCalibrationTaskConfig
    | RespondentGazeCalibrationDesktopTaskConfig
    | LumenGazeCalibrationTaskConfig
    | RespondentCompletionCodeTaskConfig
    | RespondentBvodInAppTaskConfig
    | RespondentWebPlatformTaskConfig
    | RespondentExternalSurveyTaskConfig
    | RespondentExternalLinkConfig
    | RespondentWebRequestConfig
    | RespondentTaskNoConfig
    | RespondentLinearTvConfig
    | RespondentUploadsTaskConfig
    | RespondentSnapARTaskConfig

export type RespondentGazeCalibrationTask = IRespondentTask<'gazeCalibration', RespondentGazeCalibrationTaskConfig>
export type RespondentGazeCalibrationDesktopTask = IRespondentTask<'gazeCalibrationDesktop', RespondentGazeCalibrationDesktopTaskConfig>
export type LumenGazeCalibrationTask = IRespondentTask<'lumenGazeCalibration', LumenGazeCalibrationTaskConfig>
export type RespondentBvodInAppTask = IRespondentTask<'bvodInApp', RespondentBvodInAppTaskConfig>
export type RespondentCompletionCodeTask = IRespondentTask<'completionCode', RespondentCompletionCodeTaskConfig>
export type RespondentWebPlatformTask = IRespondentTask<'webPlatform', RespondentWebPlatformTaskConfig>
export type RespondentExternalSurveyTask = IRespondentTask<'externalSurvey', RespondentExternalSurveyTaskConfig>
export type RespondentExternalLinkTask = IRespondentTask<'externalLink', RespondentExternalLinkConfig>
export type RespondentLinearTvTask = IRespondentTask<'linearTv', RespondentLinearTvConfig>
export type RespondentWebRequestTask = IRespondentTask<'webRequest', RespondentWebRequestConfig>
export type RespondentUploadsTask = IRespondentTask<'uploads', RespondentUploadsTaskConfig>
export type RespondentSnapARTask = IRespondentTask<'snapAR', RespondentSnapARTaskConfig>

export const isCompletionCodeTask = (t: RespondentTask): t is RespondentCompletionCodeTask => {
    return t.type === 'completionCode'
}

interface IRespondentTask<T extends TaskType, Config extends TaskConfig> {
    type: T,
    meta: ITaskMeta
    config: Config
}

export type RespondentTask =
    | RespondentCompletionCodeTask
    | RespondentGazeCalibrationTask
    | RespondentGazeCalibrationDesktopTask
    | LumenGazeCalibrationTask
    | RespondentBvodInAppTask
    | RespondentWebPlatformTask
    | RespondentExternalSurveyTask
    | RespondentExternalLinkTask
    | RespondentLinearTvTask
    | RespondentWebRequestTask
    | RespondentUploadsTask
    | RespondentSnapARTask

export type RespondentConfig = {
    respondentID: string
    assignmentID?: string
    groupID: string
    env: string
    flags: RespondentFlags
    extraMeta: Record<string, string>
    tasks: Array<RespondentTask>
}

export type RespondentConfigState = {
    config: RespondentConfig
    enabled: boolean
}

export type GroupId =
    | OmdGladeGroupId
    | AreMediaGroupId
    | MturkGroupId
    | AustCollGroupId
    | C9AU1021CollMobileGroupId
    | C9AU1021CollTVGroupId
    | FINE1121TVGroupId
    | TWIT1221CollGroupId
    | C7AU0122CollTVGroupId
    | REDC0122CollGroupId
    | YAHO0422CollGroupId
    | ODEU0422CollGroupId
    | SNAP0522CollGroupId
    | FXTL0722CollGroupId
    | ADCO0622CollGroupId
    | CRAA0822CollGroupId
    | VIAB0822CollGroupId
    | ACAY0822CollGroupId
    | OMNZ1022CollGroupId
    | OMME1022CollGroupId
    | NEAU1022CollGroupId
    | TEAD1222CollGroupId
    | TWIC1222CollGroupId
    | TWIH0123CollGroupId
    | SPOT0123CollGroupId
    | PEPS0523CollGroupId
    | GYGG0623CollGroupId
    | SPIN0823CollGroupId
    | SNAR0823CollGroupId
    | PINT1123CollGroupId
    | SKYN0823CollGroupId
    | RCTV0523CollGroupId
    | YAHD0823GroupId
    | GYGG0124CollGroupId
    | SPAU0324CollGroupId
    | ALLW0424CollGroupId
    | SNEM0524CollGroupId
    | GCPL0624CollGroupId
    | GCPL1024CollGroupId
    | GYGG1024CollGroupId
    | OMAU1124CollGroupId

export class ConfigurationError extends Error {
    constructor(message?: string) {
        super(message)
        this.name = "ConfigurationError"
        Object.setPrototypeOf(this, Error.prototype)
    }
}
