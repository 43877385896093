import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateWebRequestTask, generateExternalSurveyTask } from "../tasks";
import { WebPlatform } from "../types";


export const OMAU1124CollGroupIds = [
    "OMAU-435-1124-AU-TIKTOK",
    "OMAU-436-1124-AU-INSTAGRAM"
] as const;

export type OMAU1124CollGroupId = typeof OMAU1124CollGroupIds[number];

export const OMAU1124CollWebPlatformIds = [
    "OMAU-1124-TIKTOK-FEED",
    "OMAU-1124-INSTA-REELS"
] as const;

export type OMAU1124CollWebPlatformId = typeof OMAU1124CollWebPlatformIds[number]

type OMAU1124CollConfig = {
    groupId: OMAU1124CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: OMAU1124CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "OMAU-1124-TIKTOK-FEED":
        case "OMAU-1124-INSTA-REELS":
            return "10";
        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (groupId: OMAU1124CollGroupId): string => {
    switch (groupId) {
        case "OMAU-435-1124-AU-TIKTOK":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMAU-1124/OMAU-1124-AU-TIKTOK.json";
        case "OMAU-436-1124-AU-INSTAGRAM":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMAU-1124/OMAU-1124-AU-INSTAGRAM.json";
        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: OMAU1124CollGroupId): OMAU1124CollWebPlatformId => {
    switch (groupId) {
        case "OMAU-435-1124-AU-TIKTOK":
            return "OMAU-1124-TIKTOK-FEED";
        case "OMAU-436-1124-AU-INSTAGRAM":
            return "OMAU-1124-INSTA-REELS";
        default:
            assertNever(groupId);
    }
}

const getWebPlatform = (groupId: OMAU1124CollGroupId): WebPlatform => {
    switch (groupId) {
        case "OMAU-436-1124-AU-INSTAGRAM":
            return "Instagram";
        case "OMAU-435-1124-AU-TIKTOK":
            return "TikTok";
        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: OMAU1124CollGroupId): string => {
    switch (groupId) {
        case "OMAU-435-1124-AU-TIKTOK": return "https://survey.au1.qualtrics.com/jfe/form/SV_d0GAPDdHHSLCkJ0"
        case "OMAU-436-1124-AU-INSTAGRAM": return "https://survey.au1.qualtrics.com/jfe/form/SV_3K7U0apffU22fc2"
        
        default:
            assertNever(groupId);
    }
}

export const addOMAU1124CollConfig = (
    config: OMAU1124CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const FINISH_MESSAGE = "Congratulations! You have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";
    
    const langStrings = getLanguageStrings(AppLanguage.en);

    const webPlatformId = getWebPlatformId(config.groupId);
    const webplatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        platform: getWebPlatform(config.groupId),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        webPlatformID: webPlatformId,
        langStrings: langStrings,
        taskLengthMinutes: getTaskLengthMinutes(webPlatformId),
        useWebPlatformIDForTaskID: true
    });

    const externalSurveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .addTask(webplatformTask)
        .addTask(externalSurveyTask)
        .addTask(uploadsTask);

    return builder;
}