import { OMAU1124CollGroupId } from "respondent_config_generator/src/projects/OMAU-1124";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { setCurrentLang } from "../../i18n";
import { AppLanguage, getLanguageStrings } from "internationalisation";


const getPlatformForGroupId = (groupId: OMAU1124CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "OMAU-435-1124-AU-TIKTOK":
            return "tiktok";
        case "OMAU-436-1124-AU-INSTAGRAM":
            return "instagram";
        default:
            assertNever(groupId);
    }
}

export const configureOMAU1124Page = (
    landingPagebuilder: LandingPageConfigBuilder,
    params: CAIParams<OMAU1124CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en);

    landingPagebuilder.setSteps(
        LandingPageConfigBuilder.getStandardViewingSurveySteps(
            getPlatformForGroupId(params.G)
        )
    );

    landingPagebuilder.setPageTitle(
        getLanguageStrings(AppLanguage.en).LandingPage.Title
    );
}